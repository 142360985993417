import React from 'react'
import { Link } from 'gatsby'
import AniLink from "gatsby-plugin-transition-link/AniLink"
import github from '../img/github-icon.svg'
import logo from '../img/logo.svg'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
            navBarActiveClass: 'is-active',
          })
          : this.setState({
            navBarActiveClass: '',
          })
      }
    )
  }

  render() {
    return (
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <AniLink paintDrip to="/" className="navbar-item logo-link" title="Logo"
              // style={{ position: 'absolute', width: '188px', height: '180px', maxHeight: '300px !important' }}
              style={{ maxHeight: '400px !important' }}>
              <img
                src={logo}
                alt="Smart Start SL"
                style={{ width: '130%', height: '130%', maxWidth: 'none', marginLeft: '-75px' }} />
            </AniLink>
            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-end has-text-centered">
            </div>
            <div className="navbar-end has-text-centered">
              <AniLink paintDrip className="navbar-item" to="/">
                Home
              </AniLink>
              <Link className="navbar-item" to="#services">
                Our Services
              </Link>
              <AniLink paintDrip className="navbar-item" to="/blog">
                Blog
              </AniLink>
              <AniLink paintDrip className="navbar-item" to="/jobs">
                Job Posts
              </AniLink>
              <AniLink paintDrip className="navbar-item" to="/contact">
                Contact
              </AniLink>
            </div>
          </div>
        </div>
      </nav >
    )
  }
}

export default Navbar

import React from 'react'
import { Link } from 'gatsby'

import logo from '../img/logo.svg'

const Footer = class extends React.Component {
  render () {
    return (
      <footer className='footer has-blue-background has-text-white-ter'>
        <div className='content has-blue-background has-text-centered'>
          <img
            src={logo}
            alt='Smart Start SL'
            style={{ width: '14em', height: '10em' }}
          />
        </div>
        <div className='content has-text-centered has-blue-background has-text-white-ter'>
          <div className='container has-blue-background has-text-white-ter'>
            <div className='columns'>
              <div className='column is-6'>
                <section className='menu'>
                  <ul className='menu-list'>
                    <li>
                      <Link to='/' className='navbar-item'>
                        Home
                      </Link>
                    </li>
                    <li>
                      <a
                        className='navbar-item'
                        href='/admin/'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        Admin
                      </a>
                    </li>
                  </ul>
                </section>
              </div>
              <div className='column is-6'>
                <section>
                  <ul className='menu-list'>
                    <li>
                      <Link className='navbar-item' to='/blog'>
                        Latest Stories
                      </Link>
                    </li>
                    <li>
                      <Link className='navbar-item' to='/contact'>
                        Contact
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
